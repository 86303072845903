import { prodHostArr, allHostObj, LOGIN_TOKEN_KEY } from './consts'
import store from '@/store/index.ts'

/**
 * @description 生成随机字符串
 * @param {number} [num=7]
 */
export const randomStrKey = (num = 7) => {
  return Math.random().toString(36).substr(2, num)
}

export function getIsProd() {
  const url = window.location.href
  let isProd = false
  for (let i = 0; i < prodHostArr.length; i++) {
    if (url.indexOf(prodHostArr[i]) > -1) {
      isProd = true
      break
    }
  }
  return isProd
}

// 获取当前的globalHost
export const globalHost = () => {
  return getIsProd() ? allHostObj.proHost.host : allHostObj.devHost.host
}

// 获取当前的访问域名
export function getHostUrl() {
  return `https://dr.toheal.cn`
}

export function getToken() {
  return localStorage.getItem(LOGIN_TOKEN_KEY) || ''
}

function isObjectOrArray(obj) {
  return Object.prototype.toString.call(obj) === '[object Object]' || Object.prototype.toString.call(obj) === '[object Array]'
}

export function cloneDeep(obj) {
  if (isObjectOrArray(obj)) {
    var objClone = Array.isArray(obj) ? [] : {}
    if (obj && typeof obj === 'object') {
      for (let key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          if (obj[key] && typeof obj[key] === 'object') {
            objClone[key] = cloneDeep(obj[key])
          } else {
            objClone[key] = obj[key]
          }
        }
      }
    }
    return objClone
  }
  return obj
}

// 获取后缀
export function getFileNameSuffix(str) {
  if (!str) return ''
  let dotIndex = str.lastIndexOf('.')
  let suffix = str.slice(dotIndex + 1)
  suffix.toLowerCase()
  return suffix
}

//ajax post 获取流，下载file文件
export const downloadFileNetwork = (urlStr) => {
  const body = document.body || document.getElementsByTagName('body')[0]
  const form = document.createElement('form')
  form.className = 'myDownloadForm'
  form.setAttribute('action', urlStr)
  form.setAttribute('method', 'post')
  form.setAttribute('name', 'downloadForm')
  form.setAttribute('target', '_blank')
  body.appendChild(form)
  form.submit()
  setTimeout(() => {
    body.removeChild(form)
  }, 50)
}

export function delayTime(ms) {
  return new Promise((resolve) => {
    setTimeout(() => {
      return resolve()
    }, ms)
  })
}

export function tryJSONParse(jsonStr, defaultValue = {}) {
  let jsonObj = defaultValue
  try {
    const o = JSON.parse(jsonStr)
    if (['[object Object]', '[object Array]'].includes(Object.prototype.toString.call(o))) {
      jsonObj = o
    }
  } catch (err) {}
  return jsonObj
}

//去除str所有空格
export const trimAll = (str) => {
  if (!str) return ''
  return str.replace(/\s+/g, '')
}
