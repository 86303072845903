import lodash from 'lodash'

/**
 * 获取第一个菜单
 */
export const findFirstMenuUrl = ({ arr, childrenkey = 'children', urlKey = 'menuUrl' }) => {
  let url = ''
  const getFirst = (arr) => {
    if (arr && arr[0]) {
      if (arr[0][childrenkey] && arr[0][childrenkey].length > 0) {
        getFirst(arr[0][childrenkey])
      } else {
        url = arr[0][urlKey]
      }
    }
  }
  getFirst(arr)
  return url
}

export default function dealMenu(arr = [], dealRights) {
  const allMenu = lodash.cloneDeep(arr)

  const itemMap = {}
  const childrenMap = {}
  const rootArr = []
  const rightsArr = [] // 权限级别

  // rootArr.push({
  //   name: '首页',
  //   path: '/home'
  // })
  allMenu.forEach((item) => {
    // 修改item
    if (item.menuTitle) item.name = item.menuTitle
    if (item.menuUrl) item.path = item.menuUrl

    itemMap[item.menuCode] = item
    if (!childrenMap[item.menuCode]) childrenMap[item.menuCode] = []
    item.children = childrenMap[item.menuCode]

    function pushMenu() {
      if (!item.parentCode && item.menuLevel === 0) {
        // 一级目录
        rootArr.push(item)
      } else {
        // 找到它的父级的children
        if (!childrenMap[item.parentCode]) childrenMap[item.parentCode] = []
        childrenMap[item.parentCode].push(item)
      }
    }

    if (dealRights) {
      pushMenu()
    } else {
      if (item.menuLevel < 2) {
        // 菜单
        pushMenu()
      } else {
        // 权限
        rightsArr.push(item)
      }
    }
  })

  return {
    allMenu,
    menuTree: rootArr,
    rightsArr,
  }
}
