const origin = window.location.origin || ''

const reqHost = 'https://drapi.toheal.cn'

// 请求域名
export const allHostObj = {
  devHost: { text: '测试', host: reqHost },
  proHost: { text: '生产', host: reqHost },
}

// 前端代码部署的生产域名
export const prodHostArr = ['://dr.toheal.cn']

export const LOGIN_TOKEN_KEY = origin + '_LOGIN_TOKEN_KEY'
export const loginPath = '/user/login'

export const primaryColor = '#4b82f6'
