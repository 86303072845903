import axios from 'axios'
import { ElMessage } from 'element-plus'
import { getToken, globalHost } from './utils'
import { loginPath } from './consts'
import router from '@/router/index'
import store from '@/store'

const ERROR_MESSAGE = '网络异常'

// data过滤一下 null undefined
function haveValue(v) {
  if (
    //
    v === undefined ||
    v === null ||
    v === '' ||
    v === 'undefined' ||
    v === 'null'
  ) {
    return false
  }
  return true
}

export function handleTokenFail() {
  const currentPath = router.currentRoute?.value?.path
  if (currentPath !== loginPath) {
    store.dispatch('user/logout')
    router.replace(loginPath)
  }
}

/**
 *
 * @param {object} options
 * @param {string} options.url
 * @param {any} options.data
 * @param {string} [options.method='post']
 * @param {object} [options.headers={}]
 * @param {boolean} [options.errMsg=true]
 * @returns
 */
function request(options = {}) {
  const { url, data = {}, method = 'post', headers = {}, errMsg = true, ...restOptions } = options
  const token = getToken()

  const formData = new FormData()
  for (let key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const v = data[key]
      if (haveValue(v)) {
        formData.append(key, v)
      }
    }
  }

  let postUrl = url.indexOf('http:') > -1 || url.indexOf('https:') > -1 ? url : globalHost() + url
  if (token) {
    postUrl = `${postUrl}?sessionId=${token}`
  }

  return new Promise((resolve, reject) => {
    return axios
      .request({
        url: postUrl,
        method,
        headers: {
          // "Content-Type": "application/json",
          // "Content-Type": "application/x-www-form-urlencoded",
          'Content-Type': 'multipart/form-data',
          ...headers,
        },
        data: formData,
        timeout: 60 * 1000,
        ...restOptions,
      })
      .then((response) => {
        if (response.data) {
          const res = response.data
          if (res.code == '0') {
            // 业务成功
            return resolve(res.data)
          } else if (res.code === '9999') {
            // 登录失效
            if (errMsg) {
              ElMessage.closeAll()
              ElMessage.warning(res.message || '登录失效')
            }
            handleTokenFail()
            return reject(res)
          } else {
            // 业务失败
            if (errMsg) ElMessage.warning(res.message || ERROR_MESSAGE)
            return reject(res)
          }
        } else {
          // 网络没通
          if (errMsg) ElMessage.error(ERROR_MESSAGE)
          return reject(response)
        }
      })
      .catch((err) => {
        // 网络没通
        if (errMsg) ElMessage.error(ERROR_MESSAGE)
        reject(err)
      })
  })
}

export default request
