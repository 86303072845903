/* eslint-disable no-unused-vars */
import { save } from '../func'
import request from '@/utils/request'
import { getAllMenuAjax } from '@/services/user'
import { LOGIN_TOKEN_KEY, loginPath } from '@/utils/consts'
import router from '@/router/index'
import { findFirstMenuUrl } from '@/router/dealMenu'
import { cloneDeep } from 'lodash'

const defaultState = {
  userInfo: null,
  isFinishedGetMenu: false, // 是否请求完菜单
  allMenu: [],
  menuTree: [],
  rightsArr: [],
}

const user = {
  namespaced: true,
  state: () => cloneDeep(defaultState),
  getters: {
    // 菜单权限相关
    // 其实这两个变量可以放到 useMenuRights 里
    // 是否展示应用内容
    isShowContent(state) {
      if (!state.isFinishedGetMenu) {
        // 还没获取完allMenu 先认为可以展示
        return false
      } else {
        // 获取完allMenu 有allMenu 才能展示
        return state.allMenu?.length > 0 ? true : false
      }
    },
    // allMenu是否为空
    isAllMenuEmpty(state) {
      // 默认false ，当获取完allMenu，发现为空，就得跳转到登录页
      if (!state.isFinishedGetMenu) {
        return false
      } else {
        return state.allMenu?.length > 0 ? false : true
      }
    },
    firstMenu(state) {
      return findFirstMenuUrl({ arr: state.menuTree })
    },
  },
  actions: {
    initUserInfo({ state, getters, dispatch, commit }) {
      dispatch('getUserInfo').then((data) => dispatch('getPharmacistInfo', data))
      dispatch('getMenu')
    },
    getUserInfo({ state, getters, dispatch, commit }) {
      return request({
        url: '/web/getLoginStaffInfo',
      }).then((data) => {
        commit('save', { userInfo: data || {} })
        return data
      })
    },
    async getMenu({ state, getters, dispatch, commit }) {
      const menuTreeObj = await getAllMenuAjax()
      commit('save', {
        isFinishedGetMenu: true, // 说明请求过菜单了
        ...menuTreeObj,
      })
    },
    // 如果是药师 就获取药师info
    getPharmacistInfo({ state, getters, dispatch, commit }, payload) {
      const userInfo = payload
      if (userInfo?.pharmacistCode) {
        // 是药师
        return request({
          url: '/web/system/pharmacist/getPharmacistInfo',
          data: { pharmacistCode: userInfo.pharmacistCode },
        }).then((data) => {
          commit('save', {
            userInfo: {
              ...userInfo,
              pharmacistInfoDTO: data || null,
            },
          })
        })
      }
    },
    // 注销
    logout({ state, getters, dispatch, commit }) {
      commit('save', cloneDeep(defaultState))
      router.replace(loginPath)
      setTimeout(() => {
        request({ url: '/web/doLogout', errMsg: false })
        localStorage.removeItem(LOGIN_TOKEN_KEY) // 清空token
      }, 50)
    },
  },
  mutations: { save },
}

export default user
