import { createRouter, createWebHashHistory } from 'vue-router'

const route404 = {
  path: '/:catchAll(.*)',
  component: () => import('@/pages/common/404/index.vue'),
}

/**
 *
 */
const routes = [
  { path: '/', redirect: '/user/login' },
  // UserLayout
  {
    path: '/user',
    component: () => import('@/layouts/UserLayout/index.vue'),
    children: [
      {
        path: '/user/login',
        component: () => import('@/pages/login/index.vue'),
      },
    ],
  },
  {
    path: '/web',
    component: () => import('@/layouts/AppLayout/index.vue'),
    children: [
      {
        path: '/web/firstPage',
        component: () => import('@/pages/common/firstPage/index.vue'),
      },
      {
        path: '/web',
        component: () => import('@/layouts/SecurityLayout/index.vue'),
        children: [
          // 无侧边菜单栏的页面
          {
            path: '/web/system/uitemplatedata/uitemplatedatamgr/edit',
            name: '科普-编辑自定义页面',
            component: () => import('@/pages/system/templateMng/templateEdit/index.vue'),
          },
          {
            path: '/web/system/active/activemgr/edit',
            name: '活动-编辑自定义页面',
            component: () => import('@/pages/system/activeMng/activeEdit/index.vue'),
          },
          {
            path: '/web',
            component: () => import('@/layouts/BasicLayout/index.vue'),
            children: [
              // 医生集团
              {
                path: '/web/system/company/companymgr',
                name: '医生集团管理',
                component: () => import('@/pages/dr/companyMng/index.vue'),
              },
              // 医院管理
              {
                path: '/web/system/hospital/hospitalmgr',
                name: '医院管理',
                component: () => import('@/pages/dr/hospitalMng/index.vue'),
              },
              // 科室管理
              {
                path: '/web/system/depart/departmgr',
                name: '科室管理',
                component: () => import('@/pages/dr/departMng/index.vue'),
              },
              // 诊疗科目
              {
                path: '/web/system/departclass/departclassmgr',
                name: '诊疗科目',
                component: () => import('@/pages/dr/departClassMng/index.vue'),
              },
              {
                path: '/web/system/depart/departcentermgr',
                name: '科室中心',
                component: () => import('@/pages/dr/departCenterMng/index.vue'),
              },
              // 医生管理
              {
                path: '/web/system/doctor/doctormgr',
                name: '医生管理',
                component: () => import('@/pages/dr/doctorMng/index.vue'),
                meta: {
                  keepAlive: {
                    to: '/web/system/doctor/doctormgr/detail', // 去详情的时候 keep alive进行缓存
                  },
                },
              },
              {
                path: '/web/system/doctor/doctormgr/detail',
                name: '医生管理-详情',
                component: () => import('@/pages/dr/doctorMng/detail/index.vue'),
              },
              // 医生注册
              {
                path: '/web/system/doctor/doctorregistermgr',
                name: '医生注册',
                component: () => import('@/pages/dr/doctorRegisterMng/index.vue'),
                meta: {
                  keepAlive: {
                    to: '/web/system/doctor/doctorregistermgr/detail', // 去详情的时候 keep alive进行缓存
                  },
                },
              },
              {
                path: '/web/system/doctor/doctorregistermgr/detail',
                name: '医生注册-详情',
                component: () => import('@/pages/dr/doctorRegisterMng/detail/index.vue'),
              },
              /**
               * 订单管理
               */
              // 全部订单
              {
                path: '/web/system/doctororder/ordermgr',
                name: '订单管理',
                component: () => import('@/pages/order/orderMngWrap/normall.vue'),
                meta: {
                  keepAlive: {
                    to: '/web/system/doctororder/ordermgr/detail', // 去详情的时候 keep alive进行缓存
                  },
                  config: {},
                },
              },
              // 待办订单
              {
                path: '/web/system/doctororder/undoordermgr',
                name: '待办订单',
                component: () => import('@/pages/order/orderMngWrap/undo.vue'),
                meta: {
                  type: 'undo',
                  keepAlive: {
                    to: '/web/system/doctororder/ordermgr/detail', // 去详情的时候 keep alive进行缓存
                  },
                  config: {
                    orderStatus: '40,50', // 咨询中 的订单  2022.06.09再加一个 40 待接诊
                  },
                },
              },
              // 订单详情
              {
                path: '/web/system/doctororder/ordermgr/detail',
                name: '订单管理-详情',
                component: () => import('@/pages/order/orderMng/detail/index.vue'),
              },

              /**
               * product订单管理
               */
              // 全部product订单
              {
                path: '/web/system/serviceorder/ordermgr',
                name: '服务订单管理',
                component: () => import('@/pages/productOrder/orderMngWrap/normall.vue'),
                meta: {
                  keepAlive: {
                    to: '/web/system/serviceorder/ordermgr/detail', // 去详情的时候 keep alive进行缓存
                  },
                  config: {},
                },
              },
              // 待办product订单
              {
                path: '/web/system/serviceorder/undoordermgr',
                name: '待办服务订单',
                component: () => import('@/pages/productOrder/orderMngWrap/undo.vue'),
                meta: {
                  type: 'undo',
                  keepAlive: {
                    to: '/web/system/serviceorder/ordermgr/detail', // 去详情的时候 keep alive进行缓存
                  },
                  config: {
                    orderStatus: '50', // 咨询中 的订单
                  },
                },
              },
              // product订单详情
              {
                path: '/web/system/serviceorder/ordermgr/detail',
                name: '服务订单管理-详情',
                component: () => import('@/pages/productOrder/orderMng/detail/index.vue'),
              },

              /**
               * 商品管理
               */
              {
                path: '/web/system/goods/goodsmgr',
                name: '商品管理',
                component: () => import('@/pages/goods/goodsMng/index.vue'),
                meta: {
                  keepAlive: {
                    to: '/web/system/goods/goodsmgr/detail', // 去详情的时候 keep alive进行缓存
                  },
                },
              },
              // 商品详情
              {
                path: '/web/system/goods/goodsmgr/detail',
                name: '商品管理-详情',
                component: () => import('@/pages/goods/goodsMng/detail/index.vue'),
              },
              {
                path: '/web/system/doctorbanner/doctorbannermgr',
                name: '问诊轮播',
                component: () => import('@/pages/system/doctorBannerMng/index.vue'),
              },
              // 提现
              {
                path: '/web/system/doctor/doctorbalancemgr',
                name: '账户管理',
                component: () => import('@/pages/feeAccountMng/index.vue'),
              },
              {
                path: '/web/system/doctor/paymentmgr',
                name: '提现管理',
                component: () => import('@/pages/withdraw/index.vue'),
              },
              // 客户服务
              // 投诉举报
              {
                path: '/web/system/complaint/complaintmgr',
                name: '投诉举报',
                component: () => import('@/pages/complaint/complaintMng/index.vue'),
              },
              {
                path: '/web/system/order/appraisemgr',
                name: '评价管理',
                component: () => import('@/pages/dr/appraiseMng/index.vue'),
              },
              {
                path: '/web/system/user/usermgr',
                name: '用户管理',
                component: () => import('@/pages/system/userMng/index.vue'),
              },
              {
                path: '/web/system/pharmacist/pharmacistmgr',
                name: '药师管理',
                component: () => import('@/pages/system/pharmacistMng/index.vue'),
              },
              // 处方管理
              {
                path: '/web/system/prescription/prescriptionmgr',
                name: '处方管理',
                component: () => import('@/pages/order/prescriptionMng/index.vue'),
              },
              // 处方交易订单
              {
                path: '/web/system/trade/trademgr',
                name: '交易订单',
                component: () => import('@/pages/order/tradeOrderMng/index.vue'),
              },
              // 系统管理
              {
                path: '/web/system/staff/staffmgr',
                name: '员工管理',
                component: () => import('@/pages/system/staffMng/index.vue'),
              },
              {
                path: '/web/system/role/rolemgr',
                name: '角色管理',
                component: () => import('@/pages/system/roleMng/index.vue'),
              },
              {
                path: '/web/system/notice/noticemgr',
                name: '消息通知',
                component: () => import('@/pages/system/noticeMng/index.vue'),
              },
              {
                path: '/web/system/doctorapply/doctorapplymgr',
                name: '药房申请单',
                component: () => import('@/pages/system/doctorApplyMng/index.vue'),
              },
              {
                path: '/web/system/doctorncp/doctorncpmgr',
                name: '派单配置', // 就是配置全科医生
                component: () => import('@/pages/system/doctorNcpMng/index.vue'),
              },
              {
                path: '/web/system/softtext/softtextmgr',
                name: '文章管理',
                component: () => import('@/pages/system/softTextMng/index.vue'),
              },
              {
                path: '/web/system/yindaoye/yindaoyemgr',
                name: '引导页配置',
                component: () => import('@/pages/system/yindaoye/index.vue'),
              },
              {
                path: '/web/system/uitemplatedata/uitemplatedatamgr',
                name: '科普管理',
                component: () => import('@/pages/system/templateMng/index.vue'),
              },
              {
                path: '/web/system/active/activemgr',
                name: '活动管理',
                component: () => import('@/pages/system/activeMng/index.vue'),
              },
              {
                path: '/web/system/video/videomgr',
                name: '视频管理',
                component: () => import('@/pages/system/videoMng/index.vue'),
              },
              {
                path: '/web/system/video/catemgr',
                name: '视频分类管理',
                component: () => import('@/pages/system/videoMng/cate.vue'),
              },
              {
                path: '/web/system/doctor/doctorsettlemgr',
                name: '结算管理',
                component: () => import('@/pages/dr/settleMng/index.vue'),
              },
              {
                path: '/web/system/doctor/bankcardmgr',
                name: '银行卡管理',
                component: () => import('@/pages/dr/bankcardMng/index.vue'),
              },

              // {
              //   path: '/web/test',
              //   name: '开发页面',
              //   component: () => import('@/pages/patientMng/index.vue'),
              // },
              route404,
            ],
          },
          route404,
        ],
      },
      route404,
    ],
  },
  route404,
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  // keepalive滚动
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0,
      }
    }
  },
})

// router.beforeEach((to, from, next) => {
//   next();
// });

export default router
